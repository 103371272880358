
import { defineComponent } from "vue";
import store from "@/store/index";
import { User } from "@/store/workspace/types";
import { gapi } from "gapi-script";
import { gapiConfig } from "@/config/gapiConfig";
import { signInFlow } from "@/main";
import router from "@/router/index";

export default defineComponent({
  name: "Login",
  computed: {
    _loggingIn: function (): boolean {
      return store.getters["user/loggingInState"];
    },
    _signedIn: function (): boolean {
      return store.getters["user/signedIn"];
    },
  },
  methods: {
    login: function () {
      this.$gapi.getAuthInstance().then((auth) => {
        auth.signIn({ prompt: "select_account" }).then((result: any) => {
          store.commit("user/updateSignInState", signInFlow());
        });
      });
    },
  },
});
